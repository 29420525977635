import { useState, useEffect } from 'react'
import FilterContainer from '../../views/searchProducts/filterSection'
import { ProductCards } from '../../views/searchProducts/productCard'
import {
  ProductsContainer,
  ContentSection,
  ProductSection,
  ActiveFilters,
  WebFilter,
  MobileFilter,
  FilterMobile,
  ActiveFilterWrapper,
  ActiveFilterText,
} from '../../styles/searchProducts'
import { useTheme } from '../../context/newThemeContext'
import { Drawer } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import NoDataFound from 'views/EmptyData'
import Spinner from 'components/Loader'
import { CategoryFilterSection, RadioSection, Title } from '../../styles/searchProducts'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
const SearchProducts = ({
  products,
  setPriceRange,
  priceRange,
  setProducts,
  isLoading,
  categoryListing,
  selectedCategoryName,
  setSelectedCategoryName,
  // updateQueryParams,
  getAllProducts,
}) => {
  const handlePriceRangeChange = (event) => {
    const selectedPriceRange = event.target.value
    setPriceRange(selectedPriceRange)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const categoryFromUrl = selectedCategoryName ? selectedCategoryName : urlParams.get('s')
    if (categoryFromUrl) {
      setSelectedCategoryName(categoryFromUrl)
    }
  }, [setSelectedCategoryName])

  const handleRadioChange = async (event) => {
    event.preventDefault()
    const selectedValue = event.target.value
    setSelectedCategoryName(selectedValue)
    try {
      await getAllProducts(selectedValue)
    } catch (error) {
      error
    }
  }

  const { theme } = useTheme()

  const [open, setOpen] = useState(false)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const DrawerList = (
    <div style={{ padding: '20px' }}>
      <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} setOpen={setOpen} />
      <CategoryFilterSection>
        <Title>Categories</Title>

        <RadioSection>
          <RadioGroup
            aria-labelledby="category-radio-group-label"
            name="category-radio-buttons-group"
            value={selectedCategoryName}
            // onChange={handleCategoryChange}
            onChange={handleRadioChange}
            onClick={() => setOpen(false)}
          >
            {/* <FormControlLabel
                  value="All Categories"
                  control={<Radio className="radio-group" />}
                  label="All Categories"
                /> */}
            {categoryListing?.map((category, index) => {
              const formattedCategory = category.replace(/\s*\(.*?\)/, '') // Remove text inside parentheses
              return (
                <FormControlLabel
                  key={index}
                  value={formattedCategory}
                  control={<Radio className="radio-group" />}
                  label={formattedCategory}
                />
              )
            })}
          </RadioGroup>
        </RadioSection>
      </CategoryFilterSection>
    </div>
  )

  return (
    <ProductsContainer theme={theme}>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <MobileFilter>
        <FilterMobile onClick={toggleDrawer(true)}>
          <TuneIcon /> Filters
        </FilterMobile>
      </MobileFilter>
      <ContentSection>
        <WebFilter>
          <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} />

          <CategoryFilterSection>
            <Title>Categories</Title>

            <RadioSection>
              <RadioGroup
                aria-labelledby="category-radio-group-label"
                name="category-radio-buttons-group"
                value={selectedCategoryName}
                // onChange={handleCategoryChange}
                onChange={handleRadioChange}
                onClick={() => setOpen(false)}
              >
                {/* <FormControlLabel
                  value="All Categories"
                  control={<Radio className="radio-group" />}
                  label="All Categories"
                /> */}
                {categoryListing?.map((category, index) => {
                  const formattedCategory = category.replace(/\s*\(.*?\)/, '') // Remove text inside parentheses
                  return (
                    <FormControlLabel
                      key={index}
                      value={formattedCategory}
                      control={<Radio className="radio-group" />}
                      label={formattedCategory}
                    />
                  )
                })}
              </RadioGroup>
            </RadioSection>
          </CategoryFilterSection>
        </WebFilter>
        <ProductSection>
          <ActiveFilters>
            <ActiveFilterWrapper>Active Filter : {priceRange}</ActiveFilterWrapper>
            <ActiveFilterText>{`${products?.length} ${products?.length === 1 || products?.length === 0 ? 'result' : 'results'} found`}</ActiveFilterText>
          </ActiveFilters>
          {products && products?.length > 0 ? (
            <ProductCards products={products} setProducts={setProducts} />
          ) : (
            <NoDataFound content={'No Product Found'} />
          )}
          {isLoading && <Spinner />}
        </ProductSection>
      </ContentSection>
    </ProductsContainer>
  )
}

export default SearchProducts
