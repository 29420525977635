import React, { useEffect, useRef, useState, useContext } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga4'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Popover from '@mui/material/Popover'
import { Button, Drawer } from '@mui/material'
import useStyles from './style'
import { ReactComponent as LocationIcon } from 'assets/images/location.svg'
import { ReactComponent as SearchIcon } from 'assets/images/search.svg'
import { restoreToDefault } from 'constants/restoreDefaultAddress'
import { getValueFromCookie, AddCookie, deleteAllCookies } from 'utils/cookies'
import { search_types } from 'constants/searchTypes'
import ModalComponent from '../../common/Modal'
import SelectAddress from './selectAddress/selectAddress'
import AddressForm from './addressForm/addressForm'
import { address_types } from 'constants/address-types'
import { SearchContext } from 'context/searchContext'
import { AddressContext } from 'context/addressContext'
import { getCall } from 'api/axios'
import Mic from 'utils/SearchMic'
import { CartContext } from 'context/cartContext'
import { ToastContext } from 'context/toastContext'
import { LocationContext } from 'context/locationContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getOrCreateDeviceId } from 'helper'
import WishlistLogo from 'assets/svg/WishlistLogo'
import ProductCartIcon from 'assets/svg/BharathamCartIcon'
import UserIcon from 'assets/svg/BharathamUserIcon'
import { ReactComponent as MobileUserIcon } from 'assets/images/loggedInUser.svg'
import OrdersIcon from 'assets/svg/OrdersIcon'
import ComplaintIcon from 'assets/svg/ComplaintIcon'
import LogOutIcon from 'assets/svg/LogOutIcon'
import UserImage from 'assets/images/user-image.jpeg'
import logo from 'assets/images/BharathamLogo.png'
import MobileBharathamLogo from 'assets/images/BharathamLogo.png'
import MenuIcon from 'assets/svg/MenuIcon'
import LoginIcon from 'assets/svg/LoginIcon'
import OndcLogo from 'assets/images/ondc-logo.png'
import TranslateWidget from 'components/TranslatorWidget'
import {
  NavbarWrapper,
  NavbarContent,
  NavbarContentContainer,
  AddressContainer,
  DeliverAddressWrapper,
  MobileLocationWrapper,
  FavouriteWrapper,
  ItemsWrapper,
  SearchWrapper,
  SearchContent,
  FreeText,
  PincodeText,
  RightNavSection,
  MobileNavContainer,
  LogoImage,
  MenuSection,
  DrawerContainer,
  WebWrapper,
  MobileWrapper,
  WislishtWrapper,
  IconWrapper,
  UserName,
  ListWrapper,
  ListItems,
  IconsWrapper,
  ListOptions,
  ImageWrapper,
  UserWrapper,
  MenuItemWrapper,
  MenuItemSection,
  ButtonContainer,
  PopOverContentContainer,
} from 'styles/navbar'
import PaletteForm from 'views/ThemeForm/themeForm'
import { useTheme } from 'context/newThemeContext'
import { isLoggedIn } from 'utils/validateToken'
import LoginModal from 'views/LoginModal'
import ConfirmationModal from 'views/ConfirmationModal'
import { LoginContext } from 'context/loginContext'
import { getSelectCall } from 'api/axios'
import useCancellablePromise from 'api/cancelRequest'

const NavBar = ({ isCheckout = false }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  useEffect(() => {
    if (window.screen.width < 450) {
      setIsMobile(true)
    }
  }, [window.screen.width])

  const classes = useStyles()
  const history = useHistory()
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const { deliveryAddress, setDeliveryAddress } = useContext(AddressContext)

  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const criteria = useRef()
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })
  const [selectAddressModal, setSelectAddressModal] = useState(false)
  const [showTheme, setshowTheme] = useState(false)
  const [toggleAddressModal, setToggleAddressModal] = useState({
    actionType: '',
    toggle: false,
    address: restoreToDefault(),
  })
  const [addressList, setAddressList] = useState([])
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null)
  const openUserMenu = Boolean(anchorElUserMenu)
  const { cartItems, fetchCartItems } = useContext(CartContext)
  const { loggedIn } = useContext(LoginContext)
  const getPinCode = localStorage.getItem('pinCode')
  const [userData, setUserData] = useState({})
  const { setLocation, latitude, longitude } = useContext(LocationContext)
  const [currentPincode, setCurrentPinCode] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const [logout, setLogout] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { cancellablePromise } = useCancellablePromise()
  const dispatch = useContext(ToastContext)

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname === '/') {
        setSearch({ value: '' })
      }
    })

    return () => {
      unlisten()
    }
  }, [history, setSearch])

  useEffect(() => {
    const execute = async () => {
      const deviceId = await getOrCreateDeviceId()
      setDeviceId(deviceId)
    }

    execute()
  }, [])

  useEffect(() => {
    if (deviceId) fetchCartItems()
  }, [deviceId])

  const handleClickUserProfile = () => {
    history.push('/profile/user-info')
    ReactGA.event({
      category: 'Navbar',
      action: 'Click',
      label: 'Profile',
    })
  }

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null)
  }

  // use this function to get last entered values
  async function getLastEnteredValues() {
    const deviceId = await getOrCreateDeviceId()
    localStorage.setItem('deviceId', deviceId)

    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }

    if (getValueFromCookie('delivery_address')) {
      const address = JSON.parse(getValueFromCookie('delivery_address'))
      if (address) {
        setDeliveryAddress(() => address)
      }
    }
  }

  useEffect(() => {
    getLastEnteredValues()
    // fetchDeliveryAddress()
  }, [])

  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor')
    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      })
    }
  }, [locationData])

  const setCriteriaLatLng = () => {
    if (getValueFromCookie('search_context')) {
      let sc = JSON.parse(getValueFromCookie('search_context') || {})
      setSearchedLocation({
        name: sc.location?.name,
        lat: sc.location?.lat,
        lng: sc.location?.lng,
        pincode: sc.location?.pincode,
        city: sc.location?.city,
        state: sc.location?.state,
        tag: sc.location?.tag,
      })
    }

    if (search.type === search_types.PRODUCT) {
      criteria.current = {
        search_string: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      }
    }

    if (search.type === search_types.CATEGORY) {
      criteria.current = {
        category_id: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      }
    }

    if (search.type === search_types.PROVIDER) {
      criteria.current = {
        provider_id: search.value.trim(),
        delivery_location: `${searchedLocation?.lat},${searchedLocation?.lng}`,
      }
    }
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const isPopoverOpen = Boolean(anchorEl)

  useEffect(() => {
    setCriteriaLatLng()
    //sum of two variable
  }, [search])

  // get the lat and long of a place
  const fetchLatLongFromEloc = async (locationData) => {
    const { name, location } = locationData
    const { areaCode, city, lat, lng, state, tag } = location.address
    AddCookie('LatLongInfo', JSON.stringify({ lat, lng }))
    setSearchedLocation({
      ...searchedLocation,
      name: name,
      lat,
      lng,
      pincode: areaCode,
      city,
      state,
      tag: tag,
    })
    let search_context_data = getValueFromCookie('search_context')
    search_context_data = search_context_data ? Object.assign({}, JSON.parse(search_context_data)) : {}
    // generating context for search
    const search_context = {
      search: search_context_data?.search || '',
      location: {
        ...searchedLocation,
        name: name,
        lat,
        lng,
        pincode: areaCode,
        city,
        state,
        tag: tag,
      },
    }
    setLocationData(() => search_context.location)
    AddCookie('search_context', JSON.stringify(search_context))
  }

  const updateSearchParams = (searchDataUpdate) => {
    let value = ''

    if (searchDataUpdate) {
      value = searchDataUpdate.value
    } else {
      value = search.value
    }

    const categoryName = query.get('c')
    const subCategoryName = query.get('sc')
    const params = new URLSearchParams({})
    if (locationData.pathname !== '/products') {
      history.push(`/products?s=${value}`)
    } else {
      if (value) {
        params.set('s', value)
      }

      if (categoryName) {
        params.set('c', categoryName)
      }

      if (subCategoryName) {
        params.set('sc', subCategoryName)
      }

      history.replace({ pathname: locationData.pathname, search: params.toString() })
    }
  }

  const onChangeSearch = (query) => {
    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    searchDataUpdate.value = query.replace('.', '')
    setSearch(searchDataUpdate)
    // generating context for search
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))

    if (searchDataUpdate) {
      setSearchData(() => searchDataUpdate)
      updateSearchParams(searchDataUpdate)
    }
  }

  const trimCoordinates = (lat, long) => {
    const trimToSixDecimals = (num) => {
      const parsedNum = parseFloat(num)
      return isNaN(parsedNum) ? null : parsedNum.toFixed(6)
    }

    return {
      latitude: trimToSixDecimals(lat),
      longitude: trimToSixDecimals(long),
    }
  }

  // useEffect(() => {
  //   const getLocation = () => {
  //     try {
  //       if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition((position) => {
  //           setLocation(position.coords.latitude.toFixed(6), position.coords.longitude.toFixed(6))
  //         })
  //       }
  //     } catch (error) {
  //       dispatch({
  //         type: toast_actions.ADD_TOAST,
  //         payload: {
  //           id: Math.floor(Math.random() * 100),
  //           type: toast_types.error,
  //           message: 'Cant able to fetch Current Location',
  //         },
  //       })
  //     }
  //   }

  //   const getPincodeCoordinates = async () => {
  //     const { data: pincodeCoordinatesData, status } = await axios.get(
  //       `https://nominatim.openstreetmap.org/search?q=${getPinCode}&format=json&limit=1&countrycodes=IN`,
  //     )

  //     if (status === 200) {
  //       const lat = pincodeCoordinatesData[0]?.lat
  //       const long = pincodeCoordinatesData[0]?.lon

  //       const trimmedCoordinates = trimCoordinates(lat, long)
  //       setLocation(trimmedCoordinates?.latitude, trimmedCoordinates?.longitude)
  //     }
  //   }

  //   if (!getPinCode) {
  //     getLocation()
  //   } else {
  //     getPincodeCoordinates()
  //   }
  // }, [])

  useEffect(() => {
    // const DEFAULT_PINCODE = '110001' // Set your default pincode
    const DEFAULT_COORDINATES = { latitude: '19.036527', longitude: '72.868966' }

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation(position.coords.latitude.toFixed(6), position.coords.longitude.toFixed(6))
          },
          (error) => {
           error
            setLocation(DEFAULT_COORDINATES.latitude, DEFAULT_COORDINATES.longitude)
          },
        )
      } else {
        setLocation(DEFAULT_COORDINATES.latitude, DEFAULT_COORDINATES.longitude)
      }
    }

    const getPincodeCoordinates = async () => {
      try {
        const { data: pincodeCoordinatesData, status } = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${getPinCode}&format=json&limit=1&countrycodes=IN`,
        )

        if (status === 200 && pincodeCoordinatesData.length > 0) {
          const lat = pincodeCoordinatesData[0]?.lat
          const long = pincodeCoordinatesData[0]?.lon

          const trimmedCoordinates = trimCoordinates(lat, long)
          setLocation(trimmedCoordinates?.latitude, trimmedCoordinates?.longitude)
        } else {
          setLocation(DEFAULT_COORDINATES.latitude, DEFAULT_COORDINATES.longitude)
        }
      } catch (error) {
        error
        setLocation(DEFAULT_COORDINATES.latitude, DEFAULT_COORDINATES.longitude)
      }
    }

    if (!getPinCode) {
      getLocation()
    } else {
      getPincodeCoordinates()
    }
  }, [])

  const getCurrentPinCode = async () => {
    try {
      const data = await getCall(`/clientApis/v2/map/getPinCode?lat=${latitude}&lon=${longitude}`)
      setCurrentPinCode(data?.data?.postcode)
      localStorage.setItem('currentpinCode', data?.data?.postcode)
      localStorage.setItem('city', data?.data?.city)
      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Cant Fetch Pincode for your Current Location',
        },
      })
    }
  }

  useEffect(() => {
    if (latitude && longitude) {
      getCurrentPinCode()
    }
  }, [latitude, longitude])

  const CheckToken = Cookies.get('token')
  const { theme } = useTheme()
  const LogoImg = theme.uploadedImage

  const [open, setOpen] = useState(false)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const handleWishlistLogin = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/wishlist')
      setOpen(false)
    }
  }
  const wishlistLogin = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/wishlist')
      setOpen(false)
    }
  }
  const handleOrderHistory = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/orders')
      setOpen(false)
    }
  }
  const handleComplaints = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      history.push('/profile/complaint')
      setOpen(false)
    }
  }
  const getUserProfile = async () => {
    const res = await cancellablePromise(getSelectCall(`/clientApis/getUserProfile`))
    const userData = res.data
    setUserData(userData)
  }

  useEffect(() => {
    if (isLoggedIn() && !logout) {
      getUserProfile()
    }
  }, [isLoggedIn(), open, logout])

  const handleLogOut = () => {
    setLogout(true)
    setOpen(false)
    setUserData({})
  }

  const handleLogin = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      setOpen(false)
      return
    }
  }

  const handleUserProfile = () => {
    if (!isLoggedIn()) {
      setLoginModal(true)
      setOpen(false)
      return
    } else {
      history.push('/user-info')
      setOpen(false)
    }
  }

  const drawerItems = [
    {
      icon: <MobileUserIcon />,
      text: 'My Profile',
      onClick: handleUserProfile,
    },
    {
      icon: <OrdersIcon />,
      text: 'My Orders',
      onClick: handleOrderHistory,
    },

    {
      icon: <ComplaintIcon />,
      text: 'Complaints',
      onClick: handleComplaints,
    },
  ]

  const DrawerList = (
    <DrawerContainer theme={theme}>
      <UserWrapper>
        <ImageWrapper>
          <img
            src={userData && userData?.userImage ? userData?.userImage : UserImage}
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ImageWrapper>
        <UserName>{userData?.userName ? userData?.userName : ''}</UserName>
      </UserWrapper>
      <MenuItemWrapper>
        <MenuItemSection>
          <ListWrapper onClick={() => setOpen(false)}>
            {drawerItems?.map((item, index) => (
              <Link key={index} to={item.link} onClick={item.onClick}>
                <ListItems>
                  <IconsWrapper>{item.icon}</IconsWrapper>
                  <ListOptions>{item.text}</ListOptions>
                </ListItems>
              </Link>
            ))}
          </ListWrapper>
          {isMobile && <TranslateWidget />}
        </MenuItemSection>

        {CheckToken && (
          <ListWrapper>
            <ListItems onClick={() => handleLogOut()}>
              <IconsWrapper>
                <LogOutIcon />
              </IconsWrapper>
              <ListOptions>Sign out</ListOptions>
            </ListItems>
          </ListWrapper>
        )}
        {!CheckToken && (
          <ListWrapper>
            <ListItems onClick={handleLogin}>
              <IconsWrapper>
                <LoginIcon />
              </IconsWrapper>
              <ListOptions>Sign in</ListOptions>
            </ListItems>
          </ListWrapper>
        )}
      </MenuItemWrapper>
    </DrawerContainer>
  )

  return (
    <NavbarWrapper theme={theme}>
      <NavbarContentContainer>
        <AddressContainer theme={theme}>
          <div>
            <div onClick={handlePopoverOpen} style={{ cursor: 'pointer', display: 'inline-block' }}>
              <img src={OndcLogo} alt="ONDC" />
            </div>

            <Popover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <PopOverContentContainer>
                <Typography sx={{ p: 2, fontWeight: 'bold' }}>What is ONDC ?</Typography>
                <Typography sx={{ p: 2 }}>
                  ONDC (Open Network for Digital Commerce) is a government-backed initiative to create an open,
                  inclusive, and decentralized e-commerce network.
                </Typography>
              </PopOverContentContainer>
            </Popover>
          </div>

          <RightNavSection>
            {!isMobile ? <TranslateWidget /> : ''}
            <DeliverAddressWrapper onClick={() => setSelectAddressModal(true)}>
              <LocationIcon />
              <FreeText>
                <b>{getPinCode ? getPinCode : currentPincode}</b>
              </FreeText>
            </DeliverAddressWrapper>
          </RightNavSection>
        </AddressContainer>
        <div>
          <MobileLocationWrapper>
            <div>
              <div onClick={handlePopoverOpen} style={{ cursor: 'pointer', display: 'inline-block' }}>
                <img src={OndcLogo} alt="ONDC" />
              </div>

              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <PopOverContentContainer>
                  <Typography sx={{ p: 2, fontWeight: 'bold' }}>What is ONDC ?</Typography>
                  <Typography sx={{ p: 2 }}>
                    ONDC (Open Network for Digital Commerce) is a government-backed initiative to create an open,
                    inclusive, and decentralized e-commerce network.
                  </Typography>
                </PopOverContentContainer>
              </Popover>
            </div>
            <DeliverAddressWrapper onClick={() => setSelectAddressModal(true)}>
              <LocationIcon />
              <PincodeText> {getPinCode ? getPinCode : currentPincode}</PincodeText>
            </DeliverAddressWrapper>
            {/* {isMobile && <TranslateWidget />}  */}
          </MobileLocationWrapper>
          <MobileNavContainer theme={theme}>
            <MenuSection>
              <MenuIcon onClick={toggleDrawer(true)} />
              <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
              <LogoImage>
                <img src={MobileBharathamLogo} onClick={() => history.push('/')} />
              </LogoImage>
            </MenuSection>
            <MobileWrapper>
              <WislishtWrapper onClick={handleWishlistLogin}>
                <WishlistLogo />
              </WislishtWrapper>
              <Link to="/cart">
                <Badge color="error" badgeContent={cartItems?.length} className="bage-count">
                  <ProductCartIcon />
                </Badge>
                {/* <Badge color="error" badgeContent={cartItems ? cartItems?.length : cartCount} className="bage-count">
                <ProductCartIcon />
              </Badge> */}
              </Link>

              <Menu
                className={classes.userMenu}
                id="basic-menu"
                anchorEl={anchorElUserMenu}
                open={openUserMenu}
                onClose={handleCloseUserMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {CheckToken && (
                  <MenuItem
                    onClick={() => {
                      deleteAllCookies()
                      localStorage.removeItem('product_list')
                      localStorage.removeItem('cartItems')
                      history.replace('/')
                    }}
                  >
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </MobileWrapper>
            {isCheckout && (
              <div>
                <div className={classes.inputContainer}></div>
                <div className={classes.user}>
                  <UserIcon />
                  <Typography variant="body2" className={classes.userTypo}>
                    User
                  </Typography>
                </div>
              </div>
            )}
          </MobileNavContainer>
        </div>

        <NavbarContent>
          <WebWrapper>
            {LogoImg ? (
              <img
                alt="logo"
                src={LogoImg}
                className="theme_logo"
                onClick={() => history.push('/')}
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            ) : (
              <img
                alt="logo"
                src={logo}
                className="logo"
                onClick={() => history.push('/')}
                onError={(e) => {
                  e.target.src =
                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                }}
              />
            )}
          </WebWrapper>

          {!isCheckout && (
            <>
              <SearchWrapper>
                <SearchContent>
                  <IconButton
                    type="button"
                    disabled
                    aria-label="search"
                    onClick={() => {
                      setSearchData(() => search)
                      history.push(`/products?s=${search.value}`)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    fullWidth
                    className={classes.inputBase}
                    placeholder="Search..."
                    inputProps={{ 'aria-label': 'Search...' }}
                    value={search?.value || ''}
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        e.preventDefault()
                        setSearchData(() => search)
                        updateSearchParams()
                      }
                    }}
                    onChange={(e) => {
                      const searchValue = e.target.value
                      let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
                      searchDataUpdate.value = searchValue
                      setSearch(searchDataUpdate)
                      // generating context for search
                      const search_context = {
                        search: searchDataUpdate,
                        location: searchedLocation,
                      }
                      AddCookie('search_context', JSON.stringify(search_context))
                    }}
                  />
                  <Mic style={{ color: 'grey' }} onChangeSearch={onChangeSearch} />
                </SearchContent>
              </SearchWrapper>
              <WebWrapper>
                <ItemsWrapper>
                  {!loggedIn && (
                    <ButtonContainer onClick={() => handleLogin()}>
                      <Button variant="outlined" type="submit">
                        Login
                      </Button>
                    </ButtonContainer>
                  )}
                  {loggedIn && (
                    <IconWrapper>
                      <FavouriteWrapper
                        className={classes.user}
                        id="basic-button"
                        aria-controls={openUserMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openUserMenu ? 'true' : undefined}
                        onClick={handleClickUserProfile}
                      >
                        <UserIcon />
                      </FavouriteWrapper>
                    </IconWrapper>
                  )}

                  <FavouriteWrapper>
                    <WislishtWrapper onClick={wishlistLogin}>
                      <WishlistLogo />
                    </WislishtWrapper>
                  </FavouriteWrapper>
                  <FavouriteWrapper>
                    <Link to="/cart">
                      <Badge color="error" badgeContent={cartItems?.length} className="bage-count">
                        <ProductCartIcon />
                      </Badge>
                    </Link>
                  </FavouriteWrapper>
                </ItemsWrapper>
              </WebWrapper>
            </>
          )}
          {isCheckout && (
            <div>
              <div className={classes.inputContainer}></div>
              <div className={classes.user}>
                <UserIcon />
                <Typography variant="body2" className={classes.userTypo}>
                  User
                </Typography>
              </div>
            </div>
          )}
        </NavbarContent>

        {selectAddressModal && (
          <ModalComponent
            open={selectAddressModal || deliveryAddress === undefined}
            onClose={() => {
              setSelectAddressModal(false)
            }}
            title="Enter your Pin Code"
          >
            <SelectAddress
              addresses={addressList}
              onSelectAddress={(pin) => {
                fetchLatLongFromEloc(pin)
              }}
              currentPincode={currentPincode}
              onClose={() => setSelectAddressModal(false)}
              setAddAddress={() => {
                setSelectAddressModal(false)
                setToggleAddressModal({
                  actionType: 'add',
                  toggle: true,
                  address: restoreToDefault(),
                })
              }}
              setUpdateAddress={(address) => {
                setSelectAddressModal(false)
                setToggleAddressModal({
                  actionType: 'edit',
                  toggle: true,
                  address: address,
                })
              }}
            />
          </ModalComponent>
        )}
        {toggleAddressModal.toggle && (
          <ModalComponent
            open={toggleAddressModal.toggle}
            onClose={() => {
              setSelectAddressModal(false)
              setToggleAddressModal({
                actionType: '',
                toggle: false,
                address: restoreToDefault(),
              })
            }}
            title={`${toggleAddressModal.actionType === 'edit' ? `Update Delivery Address` : `Add Delivery Address`}`}
          >
            <AddressForm
              action_type={toggleAddressModal.actionType}
              address_type={address_types.delivery}
              selectedAddress={toggleAddressModal.address}
              onClose={() => {
                setToggleAddressModal({
                  actionType: '',
                  toggle: false,
                  address: restoreToDefault(),
                })
                setSelectAddressModal(true)
              }}
              onAddAddress={(address) => {
                setToggleAddressModal({
                  actionType: '',
                  toggle: false,
                  address: restoreToDefault(),
                })
                setAddressList([...addressList, address])
                setSelectAddressModal(true)
              }}
              onUpdateAddress={(address) => {
                const updatedAddress = addressList?.map((d) => {
                  if (d.id === address.id) {
                    return address
                  }

                  return d
                })
                setAddressList(updatedAddress)
                setToggleAddressModal({
                  actionType: '',
                  toggle: false,
                  address: restoreToDefault(),
                })
                setSelectAddressModal(true)
              }}
            />
          </ModalComponent>
        )}
        {showTheme && (
          <ModalComponent
            open={theme}
            onClose={() => {
              setshowTheme(false)
            }}
            title=""
          >
            <PaletteForm setshowTheme={setshowTheme} />
          </ModalComponent>
        )}
      </NavbarContentContainer>

      {logout && (
        <ModalComponent open={logout} onClose={() => setLogout(false)}>
          <ConfirmationModal
            onClose={() => setLogout(false)}
            title={'Are you sure want to sign out ?'}
            type={'logout'}
          />
        </ModalComponent>
      )}
      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} />
        </ModalComponent>
      )}
    </NavbarWrapper>
  )
}

export default NavBar
