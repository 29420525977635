import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'
import Footerbackgroound from 'assets/images/Footer.png'
export const FooterContainer = styled.div`
  width: 100%;
  background-image: url(${Footerbackgroound});
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  // background: ${theme.PRIMARYCOLOR};
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 60px 0 60px;
  color: ${theme.SECONDARYTEXTCOLOR};
  @media screen and (max-width: 450px) {
    padding: 30px 30px 0 30px;
    margin-top: 10px;
  }
`
export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
export const PaymentModesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
  img {
    width: 76px;
    height: 40px;
  }
  @media (max-width: 767px) {
    img {
      width: 45px;
      height: unset;
    }
  }
`
export const FooterDiv = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 10px;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const FooterHeading = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const DownloadFooterHeading = styled.div`
  font-weight: 500;
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`
export const FooterHeading1 = styled.div`
  font-weight: 400;
  font-size: 12px;
  list-style: none;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  a {
    color: ${theme.SECONDARYTEXTCOLOR};
    text-decoration: none;
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    svg {
      width: 17px;
      height: 17px;
    }
  }
  @media screen and (max-width: 450px) {
    svg {
      display: none;
    }
  }

  .disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const DownloadAppContainer = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 450px) {
    display: grid;
    justify-content: center;
    gap: 12px;
  }
`
export const FooterIcons = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 450px) {
    justify-content: center;
    svg {
      width: 28px;
      height: 28px;
    }
  }
`
export const DownloadImages = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  align-items: flex-start;
  margin-top: 10px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    flex-direction: row;
    img {
      width: 140px;
    }
  }
`
export const DownloadItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 450px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`
export const CopyRightSection = styled.div`
  width: 100%;
  margin-top: 28px;
  border-top: 1px solid ${theme.BORDERCOLOR};
  @media (max-width: 767px) {
    margin-top: 12px;
  }
`
export const CopyRight = styled.p`
  text-align: center;
  padding-top: 10px;
`
