import React from 'react'
import { useLocation } from 'react-router-dom'
import PageNotFound from 'components/page-not-found/pageNotFound'
import Products from '../../products/products'

const ProductRoutes = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  // If "productId" is present in the URL (regardless of value), always show PageNotFound
  if (query.has('productId')) {
    return <PageNotFound />
  }

  // Otherwise, show Products page
  return <Products />
}

export default ProductRoutes
